<template>
  <div class="back-link mb-4" @click="returnToHome()">
    <i class="ico-back"></i>
    <span>Back</span>
  </div>
</template>

<script>
export default {
  name: "btnBack",
  methods: {
    returnToHome(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss" src="./btnBack.scss"></style>