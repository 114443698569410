<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName :name="company.company.name"/>
      <btn-back/>
      <div class="title mb-2">
        Quarterly Gross Sales
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="pb-2">
            <div class="topic">
              Add your quarterly Gross Sale receipts below
            </div>
            <div class="sub-topic">
              Gross Sales is before any deductions
            </div>
          </div>

          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2019 Gross Sales
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2019.q1"
                    min="0" name="Q1-2019" type="number" placeholder="$0.00"
                    class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2019.q2"
                    min="0" name="Q2-2019" type="number" placeholder="$0.00"
                    class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2019.q3"
                    min="0" name="Q3-2019" type="number" placeholder="$0.00"
                    class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2019.q4"
                    min="0" name="Q4-2019" type="number" placeholder="$0.00"
                    class="gross-input-element row-4 form-control">
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <hr>
            </div>
          </div>

          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2020 Gross Sales
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2020.q1"
                    min="0" name="Q1-2020" type="number" placeholder="$0.00"
                    class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2020.q2"
                    min="0" name="Q2-2020" type="number" placeholder="$0.00"
                    class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2020.q3"
                    min="0" name="Q3-2020" type="number" placeholder="$0.00"
                    class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2020.q4"
                    min="0" name="Q4-2020" type="number" placeholder="$0.00"
                    class="gross-input-element row-4 form-control">
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <hr>
            </div>
          </div>


          <div class="row gross" style="padding-bottom: 0!important;">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2021 Gross Sales
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2021.q1"
                    min="0" name="Q1-2021" type="number" placeholder="$0.00"
                    class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2021.q2"
                    min="0" name="Q2-2021" type="number" placeholder="$0.00"
                    class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2021.q3"
                    min="0" name="Q3-2021" type="number" placeholder="$0.00"
                    class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.grossSales._2021.q4"
                    min="0" name="Q4-2021" type="number" placeholder="$0.00"
                    class="gross-input-element row-4 form-control">
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <hr>
            </div>
          </div>

          <div>
            <button @click="saveGrossSales()" type="button" class="btn btn-save px-5">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import {inject} from "vue"
const changeStatus = inject('changeStatus')

import Layout from "@/layouts/main";
import btnBack from "@/components/btn-back/btnBack";
import companyName from "@/components/company-name/company-name";
import {userStore} from "@/store/user";
import {companyStore} from '@/store/company';
const user = userStore();
const company = companyStore();

function saveGrossSales() {
  user.saveGrossSales(changeStatus)
}

</script>
<style scoped lang="scss" src="./gross-sales.scss"></style>